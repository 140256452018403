// component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from '../views/Login.vue'
// import Live from '../views/Live.vue'
// import Waiting from '../views/Waiting.vue'
// import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/pt', redirect: '/login/pt' },
  // { path: '/en', redirect: '/live/en' },
  { path: '/esp', redirect: '/pt' },
  { path: '/:lang', redirect: '/login/pt' },
  { path: '/', redirect: '/login/pt' },
  { path: "", redirect: '/NotFound/:lang' },
  {
    path: '/login/:password?/:lang',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    props: true,
  },
  {
    path: '/waiting/:lang',
    name: 'waiting',
    component: () => import('../views/Waiting.vue'),
  },
  {
    path: '/live/:id?/:lang',
    name: 'live',
    component: () => import('../views/Live.vue'),
    props: true,
    meta: {
      // login: true
    }
  },
  {
    path: '/notFound/:lang',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.login)) {
    if (!window.localStorage.token) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
