import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag";
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import Zendesk from '@dansmaculotte/vue-zendesk'

// setTimeout(() => {

//   Vue.use(Zendesk, {
//     key: store.state.live.Zendesk.key,
//     disabled: false,
//     hideOnLoad: false,
//     settings: {
//       webWidget: {
//         color: {
//           theme: store.state.live.Zendesk.color
//         }
//       }
//     }
//   })
  
// }, 1000);


Vue.use(VueGtag, {
  config: { id: "UA-187416562-9" }
}, router);

Vue.use(VueReCaptcha, { siteKey: '6LdYugMqAAAAAEexvwTuAf6-9X3YiuaS_aL_Kqon' })
// 6LdYugMqAAAAAHuSMv5VjWs-fjLTuprhvWjhEzO9

import Carregamento from '@/components/Carregamento.vue'
Vue.component("Carregamento", Carregamento);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'


Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
