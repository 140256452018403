import axios from 'axios';

//const url = 'http://localhost:3000';
// const url = 'https://ny2zldemy6.execute-api.us-east-1.amazonaws.com/production/live';
const url = 'https://7gm3po88hi.execute-api.us-east-1.amazonaws.com';

const axiosInstance = axios.create({
  baseURL: url,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body);
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body);
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },
  login(body) {
    return axios.post(`${url}/prod/login`, body);
  },
  solicitation(body) {
    return axios.post(`${url}/prod/solicitation`, body);
  },
};


