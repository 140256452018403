import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { api } from '../services'

export default new Vuex.Store({
  state: {
    live: null,
    login: false,
    lang: null,
    id_User: null
  },
  mutations: {
    SET_BACKGROUND_COLOR(context, payload){
      context.backgroundColor = payload;
    },
    SET_LIVE(context, payload){
      context.live = payload;
    },
    UPDATE_LOGIN(context, payload){
      context.login = payload;
    },
    UPDATE_LANG(context, payload){
      context.lang = payload;
    },
    UPDATE_ID_USER(context, payload){
      context.id_User = payload;
    },
  },
  actions: {
    async getLive(context, payload){
      var liveId = '65498732165498765408102024'
      return api.get(`/prod/live/?id=${liveId}&lang=${payload}`)
      .then(response => {
        context.commit('SET_LIVE', response.data.data);
      }).catch(error => {
        console.log(error);
      })
    },
  },
  modules: {
  } 
})
